import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import FilterReducer from 'components/FilterPane/filterPane.actions'
import ButtonReducer from 'components/PageLayout/pageLayout.actions'
import HomeReducer from 'pages/home/Home.actions'
import UitlegReducer from 'pages/uitleg/Uitleg.actions'
import OverOnsReducer from 'pages/over-ons/OverOns.actions'
import WarningModalServiceReducer from '../services/modals/WarningModal/WarningModalService.actions'
import InfoModalServiceReducer from '../services/modals/InfoModal/InfoModalService.actions'
import { MeasureDetailsReducer, MeasureTypesReducer, MeasureReducer } from 'components/Measures'

export const store = configureStore({
  reducer: {
    homePage: HomeReducer,
    uitlegPage: UitlegReducer,
    overonsPage: OverOnsReducer,
    filters: FilterReducer,
    measures: MeasureReducer,
    measureDetails: MeasureDetailsReducer,
    buttons: ButtonReducer,
    warningModalService: WarningModalServiceReducer,
    infoModalService: InfoModalServiceReducer,
    measureTypes: MeasureTypesReducer
  }
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
