import { MeasureTypeOverview, resetExpandedMeasures } from 'components/Measures'
import { Tile, TilesRow } from '..'
import { ICategoryDto } from '../../../models/generated_models'
import {
  selectActiveTile,
  setActiveTile
} from '../../../pages/home/Home.actions'
import { useAppDispatch, useAppSelector } from '../../../stores/hooks'
import './TileGrid.scss'

export type Tiles = {
  data: ICategoryDto[];
};

export const TileGrid = (props: Tiles) => {
  const activeTileId = useAppSelector(selectActiveTile)
  const dispatch = useAppDispatch()

  const maxTilesInRow = 4
  const numberOfRows = Math.ceil(props.data.length / maxTilesInRow)

  const arrayOfIndexes = new Array(numberOfRows).fill(1).map((u, i) => {
    const rowsWithData = props.data.slice(
      i * maxTilesInRow,
      (i + 1) * maxTilesInRow
    )
    return rowsWithData.map((j) => j.id)
  })

  return (
    <div className='Tiles' data-testid='Tiles'>
      {arrayOfIndexes.map((row, index1) => {
        return (
          <div key={`tilesrow${index1}`}>
            <TilesRow rowId={index1 + 1}>
              {props.data
                .slice(index1 * maxTilesInRow, (index1 + 1) * maxTilesInRow)
                .map((tile, index2) => {
                  return (
                    <Tile
                      key={`Tile${index2}`}
                      category={tile}
                      onTileClicked={(id) => {
                        dispatch(setActiveTile(id))
                        dispatch(resetExpandedMeasures())
                      }
                    }
                    />
                  )
                })}
            </TilesRow>
            {activeTileId && row.includes(activeTileId) && (
              <MeasureTypeOverview categoryId={activeTileId} key={activeTileId} />
            )}
          </div>
        )
      })}
    </div>
  )
}
