import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IOverOnsPageDto } from '../../models/generated_models'
import { AsyncState } from '../../services/types'
import { RootState } from '../../stores'

export type OverOnsState = {
  content: AsyncState<IOverOnsPageDto>
}

export const initialState: OverOnsState = {
  content: { kind: 'unloaded' }
}

export const OverOnsSlice = createSlice({
  name: 'overons',
  initialState,
  reducers: {
    setOverOnsPage: (state, action: PayloadAction<AsyncState<IOverOnsPageDto>>) => {
      state.content = action.payload
    }
  }
})

export const { setOverOnsPage } = OverOnsSlice.actions

export const getOverOnsPageContent = (state: RootState) => state.overonsPage.content

export default OverOnsSlice.reducer
