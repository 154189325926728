// Filters
const filterTitles : Record<string, string> = {
  buildingSize: 'Woninggrootte',
  homeType: 'Woningtype',
  projectsize: 'Projectgrootte'
}

// Button titles
const buttonTitles : Record<string, string> = {
  export: 'Rapport',
  feedback: 'Feedback'
}

// Navigation bar titles
const navigationBarTitles : Record<string, string> = {
  home: 'Home',
  uitleg: 'Uitleg',
  overons: 'Over ons',
  filter: 'Filter'
}

const measures = {
  noFiltersSelected: 'Er zijn geen filters geselecteerd. Selecteer filters om de kostenkentallen in te zien.',
  notAllFiltersSelected: 'Niet alle filters zijn geselecteerd. Selecteer alle filters om de kostenkentallen in te zien.',
  notFound: 'Geen maatregelen gevonden.'
}

const measureDetails = {
  noDetailsForMeasure: 'Geen details gevonden voor deze maatregel.',
  columns: {
    omschrijving: 'Omschrijving',
    specificatie: 'Specificatie',
    hoeveelheid: 'Hoeveel-heid',
    eenheid: 'Een-heid',
    totaalUurloon: 'Totaal arbeid',
    totaalMateriaal: 'Totaal materiaal',
    totaalMaterieel: 'Totaal materieel',
    eenheidsprijs: 'Eenheidsprijs directe kosten',
    totaal: 'Totaal directe kosten',
    totaalBk: 'Totaal bouwkosten',
    bouwkostenPerEenheid: 'Bouwkosten per eenheid'
  }
}

const measureHistory = {
  accordion: {
    header: 'Historie',
    createdAt: 'Maatregel opgesteld: {0}'
  },
  historyHeader: {
    date: 'Datum',
    change: 'Wijziging'
  },
  noData: 'Er zijn nog geen wijzigingen doorgevoerd aan deze maatregel.'
}

const httpInterceptorTitles : Record<string, string> = {
  header: 'Uw aanvraag is niet verzonden',
  refreshButton: 'Ververs de pagina',
  errorMessage: 'Er is een fout opgetreden. De pagina wordt ververst.'
}

const exportTypes : Record<string, string> = {
  excel: 'Excel',
  pdf: 'PDF'
}

const common : Record<string, string> = {
  errorOccurred: 'Er is een fout opgetreden.'
}

const resources = {
  filterTitles,
  buttonTitles,
  navigationBarTitles,
  measureDetails,
  measureHistory,
  measures,
  httpInterceptorTitles,
  exportTypes,
  common
}

export default resources
