import { Service } from 'react-service-locator'
import { IButtonsPageDto } from '../../models/generated_models'
import { BaseService } from '../base.services'

@Service()
export class ButtonService extends BaseService {
  async getButtonsPageAsync (): Promise<IButtonsPageDto> {
    return await fetch(`${this.basePath}/strapi-content/buttons`)
      .then(x => x.json())
  }
}
