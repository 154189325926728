import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../stores'

export type MeasureState = {
    expanded: { [key: number]: boolean }
}

const initialState: MeasureState = {
  expanded: {}
}

export const measureSlice = createSlice({
  name: 'measures',
  initialState,
  reducers: {
    setExpandedMeasure: (state, action: PayloadAction<{ measureId: number, expanded: boolean }>) => {
      if (!action.payload.expanded) {
        delete state.expanded[action.payload.measureId]
      } else {
        state.expanded[action.payload.measureId] = true
      }
    },
    resetExpandedMeasures: (state) => {
      state.expanded = {}
    }
  }
})

export const { setExpandedMeasure, resetExpandedMeasures } = measureSlice.actions

export const selectExpandedMeasure = (measureId: number) => (state: RootState) => {
  return state.measures.expanded[measureId] ?? false
}

export const selectAllExpandedMeasures = (state: RootState) => {
  return (Object.keys(state.measures.expanded) as unknown as number[])
}

export const MeasureReducer = measureSlice.reducer
