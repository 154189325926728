export type AsyncState<T> =
  | {
      kind: 'unloaded';
    }
  | {
      kind: 'loading';
    }
  | {
      kind: 'success';
      response: T;
    }
  | {
      kind: 'failure';
      msg: string;
    };

export const SuccessResponse = <T>(response: T) => {
  return {
    kind: 'success',
    response: response
  } as AsyncState<T>
}

export const FailureResponse = (msg: string) => {
  return {
    kind: 'failure',
    msg: msg
  } as AsyncState<any>
}
