import { selectBuildingSize, selectHomeType, selectProjectSize } from 'components/FilterPane/filterPane.actions'
import { useEffect } from 'react'
import { useService } from 'react-service-locator'
import { LoadingState } from 'services/constants'
import { MeasureService } from 'services/measures/measure.service'
import { FailureResponse, SuccessResponse } from 'services/types'
import { useAppDispatch, useAppSelector } from 'stores/hooks'
import { setMeasureChanges, setMeasureEntries, setMeasureFocusAreas, setMeasurePreconditions, setMeasureSummary } from './MeasureDetails.actions'
import { selectExpandedMeasure } from '../Measure/Measure.actions'

export function useMeasureEntryData (measureId: number) {
  const service = useService(MeasureService)
  const dispatch = useAppDispatch()

  const expanded = useAppSelector(selectExpandedMeasure(measureId))
  const homeType = useAppSelector(selectHomeType)
  const projectSize = useAppSelector(selectProjectSize)
  const buildingSize = useAppSelector(selectBuildingSize)

  useEffect(() => {
    if (!expanded) return

    dispatch(
      setMeasureEntries({ measureId: measureId, entries: { kind: LoadingState.Loading } })
    )

    service
      .getMeasureEntries(measureId)
      .then((response) =>
        dispatch(
          setMeasureEntries({
            measureId: measureId,
            entries: SuccessResponse(response)
          })
        )
      )
      .catch((error) =>
        dispatch(
          setMeasureEntries({
            measureId: measureId,
            entries: FailureResponse(error)
          })
        )
      )
  }, [
    measureId,
    homeType,
    projectSize,
    buildingSize,
    expanded
  ])
}

export function useMeasureFocusAreaData (measureId: number) {
  const service = useService(MeasureService)
  const dispatch = useAppDispatch()
  const expanded = useAppSelector(selectExpandedMeasure(measureId))

  useEffect(() => {
    if (!expanded) return

    dispatch(
      setMeasureFocusAreas({ measureId: measureId, entries: { kind: LoadingState.Loading } })
    )
    service
      .getMeasureFocusAreas(measureId)
      .then((response) =>
        dispatch(
          setMeasureFocusAreas({
            measureId: measureId,
            entries: SuccessResponse(response.sort((a, b) => a.order - b.order))
          })
        )
      )
      .catch((error) =>
        dispatch(
          setMeasureFocusAreas({
            measureId: measureId,
            entries: FailureResponse(error)
          })
        )
      )
  }, [
    service,
    dispatch,
    measureId,
    expanded
  ])
}

export function useMeasureSummaryData (measureId: number) {
  const service = useService(MeasureService)
  const dispatch = useAppDispatch()
  const expanded = useAppSelector(selectExpandedMeasure(measureId))

  useEffect(() => {
    if (!expanded) return

    dispatch(
      setMeasureSummary({ measureId: measureId, entries: { kind: LoadingState.Loading } })
    )

    service
      .getMeasureSummary(measureId)
      .then((response) =>
        dispatch(
          setMeasureSummary({
            measureId: measureId,
            entries: SuccessResponse(response)
          })
        )
      )
      .catch((error) =>
        dispatch(
          setMeasureSummary({
            measureId: measureId,
            entries: FailureResponse(error)
          })
        )
      )
  }, [
    service,
    dispatch,
    measureId,
    expanded
  ])
}

export function useMeasurePreconditionsData (measureId: number) {
  const service = useService(MeasureService)
  const dispatch = useAppDispatch()
  const expanded = useAppSelector(selectExpandedMeasure(measureId))

  useEffect(() => {
    if (!expanded) return

    dispatch(
      setMeasurePreconditions({ measureId: measureId, entries: { kind: LoadingState.Loading } })
    )

    service
      .getMeasurePreconditions(measureId)
      .then((response) =>
        dispatch(
          setMeasurePreconditions({
            measureId: measureId,
            entries: SuccessResponse(response)
          })
        )
      )
      .catch((error) =>
        dispatch(
          setMeasurePreconditions({
            measureId: measureId,
            entries: FailureResponse(error)
          })
        )
      )
  }, [
    service,
    dispatch,
    measureId,
    expanded
  ])
}

export function useMeasureChangesData (measureId: number) {
  const dispatch = useAppDispatch()
  const service = useService(MeasureService)
  const expanded = useAppSelector(selectExpandedMeasure(measureId))

  useEffect(() => {
    if (!expanded) return

    dispatch(setMeasureChanges({ measureId: measureId, entries: { kind: LoadingState.Loading } }))
    service.getMeasureHistory(measureId)
      .then(result => dispatch(setMeasureChanges({ measureId: measureId, entries: SuccessResponse(result) })))
      .catch(err => dispatch(setMeasureChanges({ measureId: measureId, entries: FailureResponse(err) })))
  }, [service, dispatch, measureId, expanded])
}
