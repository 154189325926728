import { useEffect } from 'react'
import { useService } from 'react-service-locator'
import { ButtonService } from 'services/buttons/buttons.service'
import { LoadingState } from 'services/constants'
import { setOpenWarningModal } from 'services/modals/WarningModal/WarningModalService.actions'
import { FailureResponse, SuccessResponse } from 'services/types'
import { useAppDispatch, useAppSelector } from 'stores/hooks'
import { selectButtons, setButtons } from './pageLayout.actions'

export function usePageLayoutData () {
  const buttons = useAppSelector(selectButtons)
  const service = useService(ButtonService)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (buttons.kind !== LoadingState.Unloaded) return
    dispatch(setButtons({ kind: LoadingState.Loading }))
    service
      .getButtonsPageAsync()
      .then((response) => dispatch(setButtons(SuccessResponse(response))))
      .catch((error) => {
        dispatch(setButtons(FailureResponse(error)))
        dispatch(setOpenWarningModal(true))
      })
  }, [service, dispatch, buttons.kind])
}
