import React from 'react'
import texts from '../../Texts'
import { LoadingSpinner } from 'components'
import { LoadingState } from 'services/constants'
import { AsyncState } from 'services/types'

type AsyncManagerProps<T> = {
    input: AsyncState<T>;
    onSuccess: (response:T)=>React.ReactNode;
    onLoading?: React.ReactNode;
    onFailure?: React.ReactNode;
}

export function AsyncResponseHandler<T> (props: AsyncManagerProps<T>) : JSX.Element {
  // if unloaded, loading or not supplied at all, show a loading spinner by default. Can be overridden by passing
  // a component as a prop
  if (!props.input || props.input.kind === LoadingState.Unloaded || props.input.kind === LoadingState.Loading) {
    return (<>{props.onLoading ?? <LoadingSpinner/>}</>)
  }
  // render the onSuccess prop component when finished processing
  if (props.input.kind === LoadingState.Success) {
    return (<>{props.onSuccess(props.input.response)}</>)
    // Otherwise, show the onFailure prop component or the default error message.
  } else {
    return (<>{props.onFailure ?? <p>{texts.common.errorOccurred}</p>}</>)
  }
}
