import React from 'react'
import { WarningModal } from 'components/WarningModal/WarningModal'
import { useAppDispatch, useAppSelector } from 'stores/hooks'
import { selectOpenWarningModal, setOpenWarningModal } from './WarningModalService.actions'

export const WarningModalService = (props:{children:React.ReactNode}) => {
  const open = useAppSelector(selectOpenWarningModal)
  const dispatch = useAppDispatch()

  const onClose = () => {
    dispatch(setOpenWarningModal(false))
    window.location.reload()
  }

  return (
    <>
        <WarningModal onHide={() => onClose()} visible={open}/>
        {props.children}
    </>
  )
}
