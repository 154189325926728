import React, { MouseEventHandler } from 'react'
import { Button as BootstrapButton } from 'react-bootstrap'
import './Button.scss'

export type ButtonProps = {
  buttonText: string;
  type: 'button' | 'submit' | 'reset';
  className?: string;
  onClick?: MouseEventHandler;
  children?: JSX.Element;
};

export const Button = (props: ButtonProps) => (
  <BootstrapButton
    data-testid='Button'
    type={props.type}
    className={`Button ${props.className}`}
    onClick={props.onClick}
  >
    {props.children ? props.children : props.buttonText}
  </BootstrapButton>
)
