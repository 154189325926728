import { saveAs } from 'file-saver'
import { Service } from 'react-service-locator'
import { IDataExportRequestDto } from '../../models/generated_models'
import { BaseService } from '../base.services'
@Service()
export class ExportService extends BaseService {
  async getExportFileAsync (exportType: string, filters: IDataExportRequestDto): Promise<void> {
    const response = await BaseService.post(`${this.basePath}/data-export/${exportType}`, filters)
    const fileName = response.headers
      .get('content-disposition')
      ?.split('filename=')[1]
      ?.split(';')[0]
      ?.replaceAll('"', '')

    const file = await response.blob()
    saveAs(file, fileName)
  }
}
