import { PageLayout } from 'components'
import { AsyncResponseHandler } from 'components/AsyncResponseHandler/AsyncResponseHandler'
import { Base } from 'pages'
import { useAppSelector } from '../../stores/hooks'
import { getUitlegPageContent } from './Uitleg.actions'
import { useUitlegData } from './uitleg.hooks'

export const Uitleg = () => {
  const uitlegPageContent = useAppSelector(getUitlegPageContent)
  useUitlegData()

  return (
    <Base>
        <AsyncResponseHandler
            input={uitlegPageContent}
            onSuccess={(data) => {
              return (
                <PageLayout
                    showFeedbackButton={true}
                    showReportButton={false}
                    pageTitle={data.title}
                    bigTitle
                >
                    <div
                        dangerouslySetInnerHTML={{
                          __html: data.content
                        }}
                    />
                </PageLayout>
              )
            }}/>
    </Base>
  )
}
