import { useFilterDto } from 'components/FilterPane/filterPane.hooks'
import { selectAllExpandedMeasures } from 'components/Measures'
import { BuildingSize, ConstructionType, HomeType, IDataExportRequestDto, ProjectSize } from 'models/generated_models'
import { selectActiveTile } from 'pages/home/Home.actions'
import { useAppSelector } from 'stores/hooks'

export function useExportRequestDto (): IDataExportRequestDto {
  const filterDto = useFilterDto()
  const expandedMeasures = useAppSelector(selectAllExpandedMeasures)
  const categoryId = useAppSelector(selectActiveTile) ?? -1

  return {
    constructionTypeCode: filterDto.constructionTypeCode,
    buildingSizeCode: filterDto.buildingSizeCode,
    homeTypeCode: filterDto.homeTypeCode,
    projectSizeCode: filterDto.projectSizeCode,
    measureIds: expandedMeasures,
    categoryId: categoryId
  }
}

export function useAllowDownload (): boolean {
  const dto = useExportRequestDto()
  if (dto.constructionTypeCode === ConstructionType.Utiliteitsbouw) {
    return true
  }
  if (
    dto.constructionTypeCode === ConstructionType.Woningbouw &&
    dto.buildingSizeCode !== BuildingSize.None &&
    dto.projectSizeCode !== ProjectSize.None &&
    dto.homeTypeCode !== HomeType.None
  ) {
    return true
  }

  return false
}
