import './Footer.scss'

export const Footer = () => (
  <footer data-testid='Footer'>
      <a href='https://www.arcadis.com/en' target='_blank' rel='noreferrer' >
          <img src='/images/arcadis-logo-white.svg' alt='Arcadis Website' />
      </a>
      <div>
        <a href='https://www.arcadis.com/en/privacy' target='_blank' rel='noreferrer'>Privacy policy</a>
        <span>&copy;{new Date().getFullYear()} Arcadis</span>
        </div>
  </footer>
)
