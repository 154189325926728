import { useEffect } from 'react'
import { useService } from 'react-service-locator'
import { LoadingState } from 'services/constants'
import { setOpenWarningModal } from 'services/modals/WarningModal/WarningModalService.actions'
import { StrapiService } from 'services/strapi/strapi.service'
import { FailureResponse, SuccessResponse } from 'services/types'
import { useAppDispatch, useAppSelector } from 'stores/hooks'
import { getOverOnsPageContent, setOverOnsPage } from './OverOns.actions'

export function useOverOnsData () {
  const strapiService = useService(StrapiService)
  const overonsPageContent = useAppSelector(getOverOnsPageContent)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (overonsPageContent.kind !== LoadingState.Unloaded) return
    dispatch(setOverOnsPage({ kind: LoadingState.Loading }))

    strapiService
      .getOverOnsPageAsync()
      .then((content) => dispatch(setOverOnsPage(SuccessResponse(content))))
      .catch((error) => {
        dispatch(setOverOnsPage(FailureResponse(error)))
        dispatch(setOpenWarningModal(true))
      })
  }, [strapiService, dispatch, overonsPageContent])
}
