import { IMeasureFocusAreaDto } from 'models/generated_models'
import '../MeasureDetailsTable.scss'

type MeasureFocusAreaProps = {
    data: IMeasureFocusAreaDto,
    index: number
}

export const MeasureFocusArea = (props: MeasureFocusAreaProps) => {
  return (
    <tr className={props.index === 0 ? 'measure-focus-area-header' : ''} key={`focusAreas${props.index}`}>
        <td>{props.data.omschrijving}</td>
        {Array.from({ length: 11 }).map((_, i) => <td key={`empty-focus-area-${props.index}-${i}`} />)}
    </tr>
  )
}
