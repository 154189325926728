import { Form } from 'react-bootstrap'

type FilterPaneProps = {
    children:React.ReactNode
}

export const FilterForm = (props: FilterPaneProps) => {
  return (
    <Form>
        {props.children}
    </Form>
  )
}
