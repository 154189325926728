import { ChangeEventHandler } from 'react'
import { Form } from 'react-bootstrap'
import { ConstructionType } from 'models/generated_models'
import { useAppDispatch, useAppSelector } from 'stores/hooks'
import { selectConstructionType } from '../filterPane.actions'
import { setActiveTile } from 'pages/home/Home.actions'
import { resetExpandedMeasures } from 'components/Measures'

export const ConstructionTypeChoiceGroup = (props:{children:React.ReactNode}) => {
  return (
      <div key={'default-radio'} className='mb-3'>
          {props.children}
      </div>
  )
}

type ConstructionTypeChoiceProps = {
    kind: ConstructionType,
    label: string,
    onClick: ChangeEventHandler<HTMLInputElement>
}

export const ConstructionTypeChoice = (props: ConstructionTypeChoiceProps) => {
  const dispatch = useAppDispatch()

  const constructionType = useAppSelector(selectConstructionType)

  return (
      <Form.Check
        inline
        type={'radio'}
        id={`${props.label}-radio`}
        label={props.label}
        checked={constructionType === props.kind}
        onChange={(e) => {
          dispatch(setActiveTile(null))
          dispatch(resetExpandedMeasures())
          props.onClick(e)
        }}
      />
  )
}
