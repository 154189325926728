import './Header.scss'
import { Container, Image } from 'react-bootstrap'
import { NavBar } from '..'

export const Header = () => (
  <header data-testid='Header'>
    <Container fluid className='text-center p-0'>
        <a href='https://www.rvo.nl' target='_blank' rel='noreferrer'>
            <Image src='/images/logo-nl.png' alt='Logo Rijksdienst voor ondernemend Nederland' />
        </a>
    </Container>
    <NavBar />
  </header>
)
