import { Accordion } from 'components/Accordion/Accordion'
import { AccordionTheme } from 'components/Accordion/Accordion.enum'
import { MeasureDetails } from 'components/Measures/MeasureDetails/MeasureDetails'
import { useAppDispatch, useAppSelector } from 'stores/hooks'
import { setExpandedMeasure } from '..'
import { selectExpandedMeasure } from './Measure.actions'
import { MeasureProps } from './Measure.props'

export const Measure = (props: MeasureProps) => {
  const dispatch = useAppDispatch()
  const expanded = useAppSelector(selectExpandedMeasure(props.data.id))

  return (
    <div className='Measure' data-testid='Measure'>
      <Accordion
        header={
          <div className='d-flex justify-content-between w-100'>
            <p className='p-0 m-0'>
                {props.data.code} | {props.data.name}
            </p>
            <p className='m-0 p-0 me-3 text-nowrap'>
                {`€${props.data.buildingPricePerUnit} / ${props.data.unit}`}
            </p>
          </div>
        }
        elementId={props.data.id}
        theme={AccordionTheme.dark}
        isFontSizeSmaller={true}
        onHeaderClick={() => dispatch(setExpandedMeasure({ measureId: props.data.id, expanded: !expanded }))}
      >
         <MeasureDetails measureId={props.data.id}/>
      </Accordion>
    </div>
  )
}
