import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../stores'

export type InfoModalServiceState = {
    openModal: boolean,
    beenVisible: boolean,
  };

export const initialState: InfoModalServiceState = {
  openModal: false,
  beenVisible: false
}

export const infoModalServiceSlice = createSlice({
  name: 'infoModal',
  initialState,
  reducers: {
    setOpenInfoModal: (state, action: PayloadAction<boolean>) => {
      state.openModal = action.payload
      // we only show the modal once per session
      if (!state.beenVisible) {
        state.beenVisible = !action.payload
      }
    }
  }
})

export const {
  setOpenInfoModal
} = infoModalServiceSlice.actions

export const selectOpenInfoModal = (state: RootState) =>
// we only show the modal once per session
  state.infoModalService.openModal && !state.infoModalService.beenVisible

export default infoModalServiceSlice.reducer
