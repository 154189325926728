import { Service } from 'react-service-locator'
import { IHomePageDto, IOverOnsPageDto, IUitlegPageDto } from '../../models/generated_models'

@Service()
export class StrapiService {
  async getUitlegPageAsync (): Promise<IUitlegPageDto> {
    return await this.fetchToJson('uitleg')
  }

  async getOverOnsPageAsync (): Promise<IOverOnsPageDto> {
    return await this.fetchToJson('over-ons')
  }

  async getHomePageAsync (): Promise<IHomePageDto> {
    return await this.fetchToJson('home')
  }

  async fetchToJson (path: string): Promise<any> {
    return fetch(`/api/strapi-content/${path}`).then((x) => x.json())
  }
}
