import { Accordion } from 'components/Accordion/Accordion'
import { AccordionTheme } from 'components/Accordion/Accordion.enum'
import { IMeasureTypeDto } from 'models/generated_models'

type MeasureTypeAccordionProps = {
  data: IMeasureTypeDto;
  children?: JSX.Element | JSX.Element[];
  onHeaderClick?:() => void
};

export const MeasureTypeAccordion = (props: MeasureTypeAccordionProps) => (
  <div className='MeasureTypeAccordion' data-testid='MeasureTypeAccordion'>
    <Accordion
      header={<span>{props.data.name}</span>}
      elementId={props.data.id}
      theme={AccordionTheme.light}
      onHeaderClick={props.onHeaderClick}
    >
      {props.children}
    </Accordion>
  </div>
)
