import './MeasureChangeHistory.scss'
import text from '../../../../Texts'
import { Accordion } from 'components/Accordion/Accordion'
import { AccordionTheme } from 'components/Accordion/Accordion.enum'
import { Col, Row } from 'react-bootstrap'
import { MeasureChangeHistoryProps } from './MeasureChangeHistory.props'
import { IMeasureChangeDto } from 'models/generated_models'

type WithChildren<T> = T & {children?: any;}

const getId = () => Math.floor(Math.random() * 100000)

/**
 * Grid for the history section
 */
const HistoryGrid = (props: WithChildren<unknown>) => {
  return (
    <div className={'history-grid mb-3 p-1'}>
        {props.children}
    </div>
  )
}

/**
 * Header for the the history section
 */
const HistoryHeader = () => {
  return (
    <Row className='mb-1'>
        <Col lg={4}><b>{text.measureHistory.historyHeader.date}</b></Col>
        <Col lg={8}><b>{text.measureHistory.historyHeader.change}</b></Col>
    </Row>
  )
}

/**
 * Item in the history section
 */
const HistoryItem = (props: {entry: IMeasureChangeDto}) => {
  return (
    <Row>
        <Col lg={4}>{props.entry.createdAtUtc}</Col>
        <Col lg={8}>{props.entry.description}</Col>
    </Row>
  )
}

const HistoryItems = (props: {changes: IMeasureChangeDto[]}) => {
  if (props.changes.length > 0) {
    return <>
            {props.changes.map((elem, index) => <HistoryItem key={index} entry={elem}/>)}
        </>
  } else {
    return <NoHistory/>
  }
}

/**
 * Displays information that this measure has no history so far
 */
const NoHistory = () => {
  return (
      <Row>
          <p>{text.measureHistory.noData}</p>
      </Row>
  )
}

/**
 * Returns the header for {@link MeasureChangeHistoryAccordion}
 *
 * @param {string} measureCreatedAt the date the measure was created
 * @return {*}
 */
const AccordionHeader = (measureCreatedAt: string) => {
  return <div className='d-flex justify-content-between w-100'>
      <p className='p-0 m-0'>
        {text.measureHistory.accordion.header}
      </p>
      <p className='m-0 p-0 me-3'>
        {text.measureHistory.accordion.createdAt.replace('{0}', measureCreatedAt)}
      </p>
  </div>
}

/**
 * Accordion that can encapsulate the history section for a measure
 *
 * @param {WithChildren<{measureCreatedAt: string}>} props
 * @return {*}
 */
const MeasureChangeHistoryAccordion = (props: WithChildren<{measureCreatedAt: string}>) => {
  return (
        <Accordion
        header={AccordionHeader(props.measureCreatedAt)}
        className='w-100'
        isFontSizeSmaller={true}
        elementId={getId()}
        theme={AccordionTheme.dark}>
            {props.children}
        </Accordion>
  )
}

/**
 * Displays the measures history
 *
 * @param {MeasureChangeHistoryProps} props
 * @return {JSX.Element}
 */
export const MeasureChangeHistory = (props: MeasureChangeHistoryProps) => {
  return (
        <div className='MeasureChangeHistory mt-3' data-testid='MeasureChangeHistory'>
            <MeasureChangeHistoryAccordion measureCreatedAt = {props.data.measureCreatedAt}>
                <HistoryGrid>
                    <HistoryHeader/>
                    <HistoryItems changes={props.data.changes}/>
                </HistoryGrid>
            </MeasureChangeHistoryAccordion>
        </div>
  )
}
