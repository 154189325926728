import { InfoModal } from 'components/InfoModal/InfoModal'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'stores/hooks'
import { selectOpenInfoModal, setOpenInfoModal } from './InfoModalService.actions'

export const InfoModalService = (props:{children:React.ReactNode}) => {
  const visible = useAppSelector(selectOpenInfoModal)
  const dispatch = useAppDispatch()

  return (
    <>
        <InfoModal visible={visible} onHide={() => dispatch(setOpenInfoModal(false))}/>
        {props.children}
    </>
  )
}
