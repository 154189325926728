import { useFilterDto } from 'components/FilterPane/filterPane.hooks'
import { BuildingSize, ConstructionType, HomeType, IMeasureDto, ProjectSize } from 'models/generated_models'
import { useEffect } from 'react'
import { useService } from 'react-service-locator'
import { LoadingState } from 'services/constants'
import { MeasureTypeService } from 'services/measures/measureType.service'
import { setOpenWarningModal } from 'services/modals/WarningModal/WarningModalService.actions'
import { FailureResponse, SuccessResponse } from 'services/types'
import { useAppDispatch } from 'stores/hooks'
import resources from 'Texts'
import { setMeasureTypes } from '../MeasureTypeOverview/MeasureTypeOverview.actions'

export function useMeasureData (measureTypeId: number) {
  const dispatch = useAppDispatch()
  const service = useService(MeasureTypeService)

  const dto = useFilterDto()

  useEffect(() => {
    dispatch(setMeasureTypes({ measureTypeId: measureTypeId, entries: { kind: LoadingState.Loading } }))

    service
      .getMeasuresAsync(measureTypeId, dto)
      .then((result: IMeasureDto[]) => {
        dispatch(setMeasureTypes({
          measureTypeId: measureTypeId,
          entries: SuccessResponse(result)
        }))
      })
      .catch((error) => {
        dispatch(setMeasureTypes({
          measureTypeId: measureTypeId,
          entries: FailureResponse(error)
        }))
        dispatch(setOpenWarningModal(true))
      })
  }, [measureTypeId, dto.buildingSizeCode, dto.constructionTypeCode, dto.homeTypeCode, dto.projectSizeCode])
}

export function useNoResultsText () {
  const dto = useFilterDto()

  const isWBouw = dto.constructionTypeCode === ConstructionType.Woningbouw

  const isWbouwDefaultSelection =
    (isWBouw &&
    (dto.buildingSizeCode === BuildingSize.None &&
        dto.homeTypeCode === HomeType.None &&
        dto.projectSizeCode === ProjectSize.None))

  const notAllFiltersSelected = (isWBouw &&
    (dto.buildingSizeCode === BuildingSize.None ||
        dto.homeTypeCode === HomeType.None ||
        dto.projectSizeCode === ProjectSize.None))

  if (isWbouwDefaultSelection) {
    return resources.measures.noFiltersSelected
  } else if (notAllFiltersSelected && !isWbouwDefaultSelection) {
    return resources.measures.notAllFiltersSelected
  } else {
    return resources.measures.notFound
  }
}
