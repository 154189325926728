import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ICategoryDto,
  IHomePageDto,
  IMeasureTypeDto
} from '../../models/generated_models'
import { AsyncState } from '../../services/types'
import { RootState } from '../../stores'

export type HomeState = {
  activeTileId: number | null;
  measureTypes: AsyncState<IMeasureTypeDto[]>;
  title: AsyncState<IHomePageDto>;
  categories: AsyncState<ICategoryDto[]>;
};

export const initialState: HomeState = {
  activeTileId: null,
  measureTypes: { kind: 'unloaded' },
  title: { kind: 'unloaded' },
  categories: { kind: 'unloaded' }
}

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setActiveTile: (state, action: PayloadAction<number | null>) => {
      state.activeTileId = action.payload
    },
    setMeasureTypes: (
      state,
      action: PayloadAction<AsyncState<IMeasureTypeDto[]>>
    ) => {
      state.measureTypes = action.payload
    },
    setHomePageTitle: (
      state,
      action: PayloadAction<AsyncState<IHomePageDto>>
    ) => {
      state.title = action.payload
    },
    setCategories: (
      state,
      action: PayloadAction<AsyncState<ICategoryDto[]>>
    ) => {
      state.categories = action.payload
    }
  }
})

export const {
  setActiveTile,
  setHomePageTitle,
  setCategories,
  setMeasureTypes
} = homeSlice.actions

export const selectActiveTile = (state: RootState) =>
  state.homePage.activeTileId

export const getHomePageTitle = (state: RootState) => state.homePage.title

export const getCategories = (state: RootState) => state.homePage.categories

export const getMeasureTypes = (state: RootState) =>
  state.homePage.measureTypes

export default homeSlice.reducer
