import { Col, Image, Row } from 'react-bootstrap'
import { useService } from 'react-service-locator'
import { ICategoryDto } from '../../../models/generated_models'
import { selectActiveTile } from '../../../pages/home/Home.actions'
import { useAppSelector } from '../../../stores/hooks'
import { TileService } from './services/tile.service'
import './Tile.scss'

export type TileProps = {
  category: ICategoryDto;
  onTileClicked: (tileId: number) => void;
};

export const Tile = (props: TileProps) => {
  const service = useService(TileService)
  const activeTileId = useAppSelector(selectActiveTile)

  return (
    <Col
      xs={3}
      data-testid='Tile'
      onClick={() => props.onTileClicked(props.category.id)}
    >
      <div
        className={`Tile mt-3 ${
          props.category.id === activeTileId ? 'clicked' : ''
        }`}
      >
        <Row>
          <Col
            xs={12}
            className='image-container d-flex justify-content-center'
          >
            <Image
              src={service.getImageForCategory(props.category.code)}
              alt=''
            />
          </Col>
          <Col xs={12} className='mb-3 mt-3 d-flex justify-content-center'>
            <h4 className='text-center'>{props.category.name}</h4>
          </Col>
        </Row>
      </div>
    </Col>
  )
}
