import { selectConstructionType } from 'components/FilterPane/filterPane.actions'
import { useEffect } from 'react'
import { useService } from 'react-service-locator'
import { setOpenInfoModal } from 'services/modals/InfoModal/InfoModalService.actions'
import { setOpenWarningModal } from 'services/modals/WarningModal/WarningModalService.actions'
import { CategoryService } from '../../services/categories/category.service'
import { LoadingState } from '../../services/constants'
import { StrapiService } from '../../services/strapi/strapi.service'
import { FailureResponse, SuccessResponse } from '../../services/types'
import { useAppDispatch, useAppSelector } from '../../stores/hooks'
import { getHomePageTitle, setCategories, setHomePageTitle } from './Home.actions'

/**
 * Retrieves data for the homepage from the backend and stores it in the application state.
 * Retrieve the result using the home actions.
 *
 * @export
 */
export function useHomepageData () {
  const dispatch = useAppDispatch()
  const homePage = useAppSelector(getHomePageTitle)
  const strapiService = useService(StrapiService)

  useEffect(() => {
    if (homePage.kind !== LoadingState.Unloaded) return

    strapiService
      .getHomePageAsync()
      .then((homepage) =>
        dispatch(setHomePageTitle(SuccessResponse(homepage)))
      )
      .catch((err) => {
        dispatch(setHomePageTitle(FailureResponse(err)))
        dispatch(setOpenWarningModal(true))
      })
  }, [strapiService, homePage, dispatch])
}

/**
 * Retrieves data for the categories from the backend and stores it in the application state.
 * Retrieve the result using the home actions.
 *
 * @export
 */
export function useCategoryData () {
  const dispatch = useAppDispatch()

  const constructionType = useAppSelector(selectConstructionType)
  const categoryService = useService(CategoryService)
  useEffect(() => {
    dispatch(setCategories({ kind: LoadingState.Loading }))

    categoryService
      .getCategoriesAsync(constructionType)
      .then((cats) => dispatch(setCategories(SuccessResponse(cats))))
      .catch((err) => dispatch(setCategories(FailureResponse(err))))
      .finally(() => dispatch(setOpenInfoModal(true)))
  }, [constructionType])
}
