import './FilterPane.scss'
import text from '../../Texts'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'stores/hooks'
import { LoadingSpinner } from 'components'
import { useFilterPaneData } from './filterPane.hooks'
import { selectBuildingSize, selectConstructionType, selectFilterOptions, selectHomeType, selectProjectSize, setBuildingSize, setConstructionType, setHomeType, setProjectSize } from './filterPane.actions'
import { FilterForm } from './FilterForm/FilterForm'
import { FilterOption } from './FilterOption/FilterOption'
import { ConstructionType, IFilterOptionsDto } from 'models/generated_models'
import { ConstructionTypeChoice, ConstructionTypeChoiceGroup } from './ConstructionType/ConstructionType'
import { FilterOptionGroup } from './FilterOption/FilterOptionGroup'
import { AsyncResponseHandler } from 'components/AsyncResponseHandler/AsyncResponseHandler'

const FilterPaneContainer = (props:{children: React.ReactNode}) => {
  return (
    <div>
        <div className='paneHead mt-3'>
        <h4>{text.navigationBarTitles.filter}</h4>
        </div>
        <div className='FilterPane' data-testid='FilterPane'>
            {props.children}
        </div>
    </div>
  )
}

const LoadingIndicator = () => {
  return (
    <FilterPaneContainer>
        <LoadingSpinner/>
    </FilterPaneContainer>
  )
}

const FilterPaneContents = (props:{options: IFilterOptionsDto}) => {
  const dispatch = useAppDispatch()

  const constructionType = useAppSelector(selectConstructionType)
  const homeType = useAppSelector(selectHomeType)
  const projectSize = useAppSelector(selectProjectSize)
  const buildingSize = useAppSelector(selectBuildingSize)

  return (
    <FilterPaneContainer>
        <FilterForm>
            <ConstructionTypeChoiceGroup>
                <ConstructionTypeChoice
                    label={'Utiliteitsbouw'}
                    kind={ConstructionType.Utiliteitsbouw}
                    onClick={(_) => dispatch(setConstructionType(ConstructionType.Utiliteitsbouw))}
                />
                <ConstructionTypeChoice
                    label={'Woningbouw'}
                    kind={ConstructionType.Woningbouw}
                    onClick={(_) => dispatch(setConstructionType(ConstructionType.Woningbouw))}
                />
            </ConstructionTypeChoiceGroup>
            <FilterOptionGroup hidden={constructionType === ConstructionType.Utiliteitsbouw}>
                <FilterOption
                        label={text.filterTitles.projectsize}
                        defaultValue={projectSize}
                        onChange={(e) => dispatch(setProjectSize(Number.parseInt(e.target.value)))}
                        options={props.options.projectSizeOptions}
                    />
                <FilterOption
                        label={text.filterTitles.homeType}
                        defaultValue={homeType}
                        onChange={e => dispatch(setHomeType(Number.parseInt(e.target.value)))}
                        options={props.options.homeTypeOptions}
                    />
                <FilterOption
                        label={text.filterTitles.buildingSize}
                        defaultValue={buildingSize}
                        onChange={e => dispatch(setBuildingSize(Number.parseInt(e.target.value)))}
                        options={props.options.buildingSizeOptions}
                    />
            </FilterOptionGroup>
            </FilterForm>
    </FilterPaneContainer>
  )
}

export const FilterPane = () => {
  useFilterPaneData()
  const filterOptions = useAppSelector(selectFilterOptions)

  return <AsyncResponseHandler
    input={filterOptions}
    onSuccess={(response) => <FilterPaneContents options={response}/>}
    onLoading={<LoadingIndicator/>}
  />
}
