import { Service } from 'react-service-locator'
import { BaseService } from 'services/base.services'
import { IMeasureEntryDto, IMeasureFocusAreaDto, IMeasureChangeHistoryDto, IMeasurePreconditionDto } from '../../models/generated_models'

@Service()
export class MeasureService extends BaseService {
  async getMeasureEntries (measureId: number): Promise<IMeasureEntryDto[]> {
    return await fetch(`${this.basePath}/measures/${measureId}/measure-entries`)
      .then(x => x.json())
  }

  async getMeasureHistory (measureId: number):Promise<IMeasureChangeHistoryDto> {
    return fetch(`${this.basePath}/measures/${measureId}/measure-changes`)
      .then(x => x.json())
  }

  async getMeasureFocusAreas (measureId: number): Promise<IMeasureFocusAreaDto[]> {
    return fetch(`${this.basePath}/measures/${measureId}/measure-focus-areas`)
      .then(x => x.json())
  }

  async getMeasurePreconditions (measureId: number): Promise<IMeasurePreconditionDto[]> {
    return fetch(`${this.basePath}/measures/${measureId}/measure-preconditions`)
      .then(x => x.json())
  }

  async getMeasureSummary (measureId: number): Promise<IMeasureEntryDto> {
    return fetch(`${this.basePath}/measures/${measureId}/measure-summary`)
      .then(x => x.json())
  }
}
