import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HomeType, ProjectSize, BuildingSize, IFilterOptionsDto, ConstructionType } from 'models/generated_models'
import { AsyncState } from 'services/types'
import { RootState } from 'stores'

interface FilterState {
  filterOptions: AsyncState<IFilterOptionsDto>,
  constructionType: ConstructionType,
  homeType: HomeType,
  projectSize: ProjectSize,
  buildingSize: BuildingSize
}

const initialState: FilterState = {
  filterOptions: { kind: 'unloaded' },
  constructionType: ConstructionType.Woningbouw,
  homeType: HomeType.None,
  projectSize: ProjectSize.None,
  buildingSize: BuildingSize.None
}

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFilterOptions: (state, action: PayloadAction<AsyncState<IFilterOptionsDto>>) => {
      state.filterOptions = action.payload
    },
    setConstructionType: (state, action: PayloadAction<ConstructionType>) => {
      state.constructionType = action.payload
    },
    setHomeType: (state, action: PayloadAction<HomeType>) => {
      state.homeType = action.payload
    },
    setProjectSize: (state, action: PayloadAction<ProjectSize>) => {
      state.projectSize = action.payload
    },
    setBuildingSize: (state, action: PayloadAction<BuildingSize>) => {
      state.buildingSize = action.payload
    }
  }
})

export const { setFilterOptions, setConstructionType, setHomeType, setProjectSize, setBuildingSize } = filterSlice.actions

export const selectFilterOptions = (state:RootState) => state.filters.filterOptions
export const selectConstructionType = (state:RootState) => state.filters.constructionType
export const selectHomeType = (state:RootState) => state.filters.homeType
export const selectProjectSize = (state:RootState) => state.filters.projectSize
export const selectBuildingSize = (state:RootState) => state.filters.buildingSize

export default filterSlice.reducer
