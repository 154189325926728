import { BuildingSize, ConstructionType, HomeType, IFilterCombinationDto, ProjectSize } from 'models/generated_models'
import { useEffect } from 'react'
import { useService } from 'react-service-locator'
import { BackendService } from 'services/backend.service'
import { LoadingState } from 'services/constants'
import { setOpenWarningModal } from 'services/modals/WarningModal/WarningModalService.actions'
import { FailureResponse, SuccessResponse } from 'services/types'
import { useAppDispatch, useAppSelector } from 'stores/hooks'
import { selectBuildingSize, selectConstructionType, selectFilterOptions, selectHomeType, selectProjectSize, setFilterOptions } from './filterPane.actions'

export function useFilterPaneData () {
  const dispatch = useAppDispatch()
  const filterOptions = useAppSelector(selectFilterOptions)

  const backendService = useService(BackendService)

  useEffect(() => {
    if (filterOptions.kind !== LoadingState.Unloaded) { return }

    dispatch(setFilterOptions({ kind: LoadingState.Loading }))

    backendService
      .getFilterOptionsAsync()
      .then((response) => dispatch(setFilterOptions(SuccessResponse(response))))
      .catch((error) => {
        dispatch(setFilterOptions(FailureResponse(error)))
        dispatch(setOpenWarningModal(true))
      })
  }, [filterOptions.kind, dispatch, backendService])
}

export function useFilterDto (): IFilterCombinationDto {
  const constructionType = useAppSelector(selectConstructionType)
  const buildingSize = useAppSelector(selectBuildingSize)
  const homeType = useAppSelector(selectHomeType)
  const projectSize = useAppSelector(selectProjectSize)

  // Utiliteitsbouw does not use filters, so they can be set to their defaults
  if (constructionType === ConstructionType.Utiliteitsbouw) {
    return {
      constructionTypeCode: ConstructionType.Utiliteitsbouw,
      buildingSizeCode: BuildingSize.None,
      homeTypeCode: HomeType.None,
      projectSizeCode: ProjectSize.None
    }
  }

  // else its Woningbouw, which does utilize filters. Select the from application state
  // and return the dto
  return {
    constructionTypeCode: constructionType,
    buildingSizeCode: buildingSize,
    homeTypeCode: homeType,
    projectSizeCode: projectSize
  }
}
