export const makeQueryString = (dto: any): string => {
  let queryString = ''

  const propList = Object.keys(dto)

  propList.forEach((prop) => {
    if (dto[prop] !== null && dto[prop] !== '') {
      queryString += `${prop}=${(dto[prop])}&`
    } else {
      queryString += `${prop}&`
    }
  })

  queryString = queryString.slice(0, -1)
  return queryString
}
