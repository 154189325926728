import { Service } from 'react-service-locator'
import bodemvoorzieningen from '../../../../assets/images/tiles/bodemvoorzieningen.png'
import buitenwanden from '../../../../assets/images/tiles/buitenwanden.png'
import vloeren from '../../../../assets/images/tiles/vloeren.png'
import buitenwandopeningen from '../../../../assets/images/tiles/buitenwandopeningen.png'
import plafondafwerkingen from '../../../../assets/images/tiles/plafondafwerkingen.png'
import dakafwerkingen from '../../../../assets/images/tiles/dakafwerkingen.png'
import warmteOpwekking from '../../../../assets/images/tiles/warmte-opwekking.png'
import water from '../../../../assets/images/tiles/water.png'
import koudeOpwekking from '../../../../assets/images/tiles/koude-opwekking.png'
import warmteDistributie from '../../../../assets/images/tiles/warmte-distributie.png'
import luchtbehandeling from '../../../../assets/images/tiles/luchtbehandeling.png'
import regelingKlimaatEnSanitair from '../../../../assets/images/tiles/regeling-klimaat-en-sanitair.png'
import centraleElectrotechnischeVoorzieningen from '../../../../assets/images/tiles/centrale-elektrotechnische-voorzieningen.png'
import verlichting from '../../../../assets/images/tiles/verlichting.png'
import afvoeren from '../../../../assets/images/tiles/afvoeren.png'
import gassen from '../../../../assets/images/tiles/gassen.png'
import vasteSanitaireVoorzieningen from '../../../../assets/images/tiles/vaste-sanitaire-voorzieningen.png'

@Service()
export class TileService {
  public getImageForCategory (id: number) : string {
    const dict = {
      11: bodemvoorzieningen,
      21: buitenwanden,
      23: vloeren,
      31: buitenwandopeningen,
      45: plafondafwerkingen,
      47: dakafwerkingen,
      51: warmteOpwekking,
      52: afvoeren,
      53: water,
      54: gassen,
      55: koudeOpwekking,
      56: warmteDistributie,
      57: luchtbehandeling,
      58: regelingKlimaatEnSanitair,
      61: centraleElectrotechnischeVoorzieningen,
      63: verlichting,
      74: vasteSanitaireVoorzieningen
    }

    return (dict as any)[id] as string
  }
}
