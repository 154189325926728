export abstract class BaseService {
   protected basePath = '/api';

   protected static post (path: string, body: any) : Promise<Response> {
     return fetch(path,
       {
         headers: {
           Accept: 'application/json',
           'Content-Type': 'application/json'
         },
         method: 'POST',
         body: JSON.stringify(body)
       })
   }
}
