import { IPartnerDto } from 'models/generated_models'
import React from 'react'
import { Image } from 'react-bootstrap'

import './Partner.scss'

type PartnerProps = {
    partner: IPartnerDto
}

export const Partner = (props: PartnerProps) => (
    <div data-testid='Partner' className='partner'>
        <Image
            src={props.partner.logo?.url}
            alt={props.partner.logo?.alternativeText}
        />
    </div>
)
