import { IMeasureTypeDto } from 'models/generated_models'
import { setMeasureTypes } from 'pages/home/Home.actions'
import { useEffect } from 'react'
import { useService } from 'react-service-locator'
import { CategoryService } from 'services/categories/category.service'
import { LoadingState } from 'services/constants'
import { setOpenWarningModal } from 'services/modals/WarningModal/WarningModalService.actions'
import { FailureResponse, SuccessResponse } from 'services/types'
import { useAppDispatch } from 'stores/hooks'

export function UseMeasureTypes (categoryId: number) {
  const service = useService(CategoryService)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setMeasureTypes({ kind: LoadingState.Loading }))
    service
      .getMeasureTypesAsync(categoryId)
      .then((result: IMeasureTypeDto[]) =>
        dispatch(setMeasureTypes(SuccessResponse(result)))
      )
      .catch((error) => {
        dispatch(setMeasureTypes(FailureResponse(error)))
        dispatch(setOpenWarningModal(true))
      })
  }, [service, categoryId, dispatch])
}
