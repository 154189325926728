import { Service } from 'react-service-locator'
import { BaseService } from 'services/base.services'
import { ConstructionType, ICategoryDto, IMeasureTypeDto } from '../../models/generated_models'

@Service()
export class CategoryService extends BaseService {
  async getCategoriesAsync (constructionType: ConstructionType): Promise<ICategoryDto[]> {
    return await fetch(`${this.basePath}/categories/${constructionType}`)
      .then(x => x.json())
  }

  async getMeasureTypesAsync (categoryId: number): Promise<IMeasureTypeDto[]> {
    return await fetch(`${this.basePath}/categories/${categoryId}/measuretypes`)
      .then(x => x.json())
  }
}
