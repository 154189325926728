import React from 'react'
import { Button } from 'components'
import { Modal } from 'react-bootstrap'
import text from '../../Texts'
import './WarningModal.scss'

type WarningModalProps = {
    onHide: ()=>void
    visible: boolean
}

export const WarningModal = (props: WarningModalProps) => {
  return (
    <div data-testid='WarningModal'>
      <Modal className='WarningModal' show={props.visible} backdrop='static'>
        <Modal.Header>
          <Modal.Title>{text.httpInterceptorTitles.header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{text.httpInterceptorTitles.errorMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={props.onHide}
            buttonText={text.httpInterceptorTitles.refreshButton}
            type='button'
          />
        </Modal.Footer>
      </Modal>
    </div>
  )
}
