import 'reflect-metadata'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { ServiceContainer } from 'react-service-locator'
import { Routing } from './components'
import reportWebVitals from './reportWebVitals'
import { BackendService } from './services/backend.service'
import { StrapiService } from './services/strapi/strapi.service'
import { store } from './stores'
import { WarningModalService } from 'services/modals/WarningModal/WarningModalService'
import './styles/styles.scss'

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ServiceContainer services={[StrapiService, BackendService]}>
                <WarningModalService>
                    <Router>
                        <Routing />
                    </Router>
                </WarningModalService>
            </ServiceContainer>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
