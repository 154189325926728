import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IMeasureChangeHistoryDto, IMeasureEntryDto, IMeasureFocusAreaDto, IMeasurePreconditionDto } from '../../../models/generated_models'
import { AsyncState } from '../../../services/types'
import { RootState } from '../../../stores'

export type MeasureDetailsState = {
    measureEntries: { [key: number]: AsyncState<IMeasureEntryDto[]> }
    measureFocusAreas: { [key: number]: AsyncState<IMeasureFocusAreaDto[]> }
    measurePreconditions: { [key: number]: AsyncState<IMeasurePreconditionDto[]> }
    measureSummary: { [key: number]: AsyncState<IMeasureEntryDto> }
    measureChanges: { [key: number]: AsyncState<IMeasureChangeHistoryDto> }
}

const initialState: MeasureDetailsState = {
  measureEntries: {},
  measureFocusAreas: {},
  measureChanges: {},
  measureSummary: {},
  measurePreconditions: {}
}

export const measureDetailSlice = createSlice({
  name: 'measureDetails',
  initialState,
  reducers: {
    setMeasureEntries: (state, action: PayloadAction<{ measureId: number, entries: AsyncState<IMeasureEntryDto[]> }>) => {
      state.measureEntries[action.payload.measureId] = action.payload.entries
    },
    setMeasureFocusAreas: (state, action: PayloadAction<{ measureId: number, entries: AsyncState<IMeasureFocusAreaDto[]> }>) => {
      state.measureFocusAreas[action.payload.measureId] = action.payload.entries
    },
    setMeasureChanges: (state, action: PayloadAction<{ measureId: number, entries: AsyncState<IMeasureChangeHistoryDto> }>) => {
      state.measureChanges[action.payload.measureId] = action.payload.entries
    },
    setMeasurePreconditions: (state, action: PayloadAction<{ measureId: number, entries: AsyncState<IMeasurePreconditionDto[]> }>) => {
      state.measurePreconditions[action.payload.measureId] = action.payload.entries
    },
    setMeasureSummary: (state, action: PayloadAction<{ measureId: number, entries: AsyncState<IMeasureEntryDto> }>) => {
      state.measureSummary[action.payload.measureId] = action.payload.entries
    }
  }
})

export const { setMeasureEntries, setMeasureFocusAreas, setMeasureChanges, setMeasurePreconditions, setMeasureSummary } = measureDetailSlice.actions

export const getMeasureEntries = (measureId: number) => (state: RootState) => state.measureDetails.measureEntries[measureId]
export const getMeasureFocusAreas = (measureId: number) => (state: RootState) => state.measureDetails.measureFocusAreas[measureId]
export const getMeasureChanges = (measureId: number) => (state: RootState) => state.measureDetails.measureChanges[measureId]
export const getMeasurePreconditions = (measureId: number) => (state: RootState) => state.measureDetails.measurePreconditions[measureId]
export const getMeasureSummary = (measureId: number) => (state: RootState) => state.measureDetails.measureSummary[measureId]

export const MeasureDetailsReducer = measureDetailSlice.reducer
