import { Service } from 'react-service-locator'
import { IFilterOptionsDto } from '../models/generated_models'

@Service()
export class BackendService {
  async getFilterOptionsAsync (): Promise<IFilterOptionsDto> {
    return await fetch('/api/filteroptions')
      .then(x => x.json())
  }
}
