import { Modal, Image } from 'react-bootstrap'
import Infographic from '../../assets/images/infographic.png'
import './InfoModal.scss'

type InfoModalProps = {
    visible: boolean
    onHide: ()=>void
}

export const InfoModal = (props: InfoModalProps) => {
  return (
    <div data-testid='InfoModal'>
      <Modal
        className='InfoModal'
        show={props.visible}
        onHide={props.onHide}
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title/>
        </Modal.Header>
        <Modal.Body>
            <a href={Infographic} target='_blank' rel='noreferrer'>
                <Image className={'rounded mx-auto d-block'} src={Infographic}/>
            </a>
        </Modal.Body>
      </Modal>
    </div>
  )
}
