type FilterOptionGroupProps = {
    hidden: boolean;
    children:React.ReactNode
}

export const FilterOptionGroup = (props:FilterOptionGroupProps) => {
  return (
        <div hidden={props.hidden}>
            {props.children}
        </div>
  )
}
