import { Col, Container, Row } from 'react-bootstrap'
import { Footer, Header } from '../../components'
import './Base.scss'

type BaseProps = {
  sideBar?: JSX.Element | JSX.Element[] | string;
  children?: JSX.Element | JSX.Element[] | string;
};

export function Base (props: BaseProps) {
  return (
    <>
      <main>
        <Header />
        <Container fluid data-testid='Base' className='p-0'>
          <Row className='m-auto' style={{ maxWidth: '1400px' }}>
            {props.sideBar && <Col xs={3}>{props.sideBar}</Col>}
            <Col xs={props.sideBar ? 9 : 12} className='p-4'>
              {props.children}
            </Col>
          </Row>
        </Container>
      </main>
      <Footer />
    </>
  )
}
