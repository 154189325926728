//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export interface ICategoryDto
{
	id: number;
	order: number;
	name?: string;
	code: number;
}
export interface IFilterOptionItemDto<T>
{
	code: T;
	name: string;
}
export interface IFilterOptionsDto
{
	buildingSizeOptions: IFilterOptionItemDto<BuildingSize>[];
	homeTypeOptions: IFilterOptionItemDto<HomeType>[];
	projectSizeOptions: IFilterOptionItemDto<ProjectSize>[];
}
export interface IMeasureChangeDto
{
	id: number;
	description: string;
	createdAtUtc: string;
}
export interface IMeasureChangeHistoryDto
{
	measureCreatedAt: string;
	changes: IMeasureChangeDto[];
}
export interface IMeasureDto
{
	id: number;
	order: number;
	code?: string;
	name?: string;
	description?: string;
	unit?: string;
	unitPrice: string;
	buildingPricePerUnit: string;
	createdAtUtc?: string;
}
export interface IMeasureEntryDto
{
	id: number;
	order: number;
	omschrijving?: string;
	specificatie?: string;
	hoeveelheid?: string;
	eenheid?: string;
	uren?: string;
	brutoUurloon?: string;
	brutoMateriaal?: string;
	brutoMaterieel?: string;	
	bouwkostenPerEenheid?: string;
	eenheidsprijs?: string;
	totaal?: string;
	totaalBk?: string;
}
export interface IMeasureFocusAreaDto
{
	id: number;
	order: number;
	code?: number;
	omschrijving?: string;
}
export interface IMeasurePreconditionDto
{
	id: number;
	code?: number;
	omschrijving?: string;
	hoeveelheid?: string;
	eenheid?: string;
}
export interface IMeasureTypeDto
{
	id: number;
	order: number;
	name?: string;
}
export interface IButtonDto
{
	name?: string;
	targetAddress?: string;
}
export interface IButtonsPageDto
{
	feedbackButton?: IButtonDto;
}
export interface IHomePageDto
{
	title: string;
}
export interface IOverOnsPageDto
{
	title: string;
	content: string;
	partnersTitle: string;
	partners: IPartnerDto[];
}
export interface IPartnerDto
{
	name?: string;
	logo?: IPartnerLogoDto;
}
export interface IPartnerLogoDto
{
	alternativeText?: string;
	url?: string;
}
export interface IUitlegPageDto
{
	title: string;
	content: string;
}
export interface IFilterCombinationDto
{
	constructionTypeCode: ConstructionType;
	buildingSizeCode: BuildingSize;
	homeTypeCode: HomeType;
	projectSizeCode: ProjectSize;
}
export interface IDataExportRequestDto extends IFilterCombinationDto
{
	categoryId: number;
	measureIds: number[];
}
export enum BuildingSize {
	None = 1,
	Klein = 2,
	Middel = 3,
	Groot = 4
}
export enum ConstructionType {
	Utiliteitsbouw = 1,
	Woningbouw = 2
}
export enum HomeType {
	None = 1,
	Eengezinswoning = 2,
	Meergezinswoning = 3
}
export enum ProjectSize {
	None = 1,
	EnkelvoudigeAanpak = 2,
	ProjectmatigeAanpak = 3
}
