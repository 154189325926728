import React from 'react'
import { Row } from 'react-bootstrap'
import './TileRow.scss'

export type TilesRowProps = {
  rowId: number;
  children?: JSX.Element[];
};

export const TilesRow = (props: TilesRowProps) => {
  return <Row className='justify-content-left pl-1 pr-1' data-testid='TilesRow'>{props.children}</Row>
}
