import { PageLayout } from 'components'
import { AsyncResponseHandler } from 'components/AsyncResponseHandler/AsyncResponseHandler'
import { Base } from 'pages'
import { useAppSelector } from '../../stores/hooks'
import { Partner } from './partner/Partner'
import { getOverOnsPageContent } from './OverOns.actions'
import { useOverOnsData } from './OverOns.hooks'
import './OverOns.scss'

export const OverOns = () => {
  const overonsPageContent = useAppSelector(getOverOnsPageContent)
  useOverOnsData()

  return (
    <Base>
      <AsyncResponseHandler
        input={overonsPageContent}
        onSuccess={(data) => {
          return (
            <PageLayout
              showFeedbackButton={true}
              showReportButton={false}
              pageTitle={data.title}
              bigTitle
            >
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.content
                  }}
                ></div>
                <h2 data-testid='overonspartnerstitle'>
                  {data.partnersTitle}
                </h2>
                <div className='partners'>
                  {data.partners.length > 0 &&
                    data.partners.map(
                      (p, i) => p.logo?.url && <Partner partner={p} key={i} />
                    )}
                </div>
              </div>
            </PageLayout>
          )
        }}
      />
    </Base>
  )
}
