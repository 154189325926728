import { IMeasurePreconditionDto } from 'models/generated_models'
import '../MeasureDetailsTable.scss'

type MeasurePreconditionProps = {
    data: IMeasurePreconditionDto,
    index: number
}
export const MeasurePrecondition = (props: MeasurePreconditionProps) => {
  return (
    <tr className={'measure-precondition'}>
    <td>{props.data.omschrijving}</td>
    <td />
    <td>{props.data.hoeveelheid}</td>
    <td>{props.data.eenheid}</td>
    {Array.from({ length: 8 }).map((_, i) => <td key={`empty-precondition-${props.index}-${i}`}/>)}
    </tr>)
}
