import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IButtonsPageDto } from 'models/generated_models'
import { AsyncState } from 'services/types'
import { RootState } from 'stores'

interface PageLayoutState {
  content: AsyncState<IButtonsPageDto>;
}

const initialState: PageLayoutState = {
  content: { kind: 'unloaded' }
}

export const buttonSlice = createSlice({
  name: 'buttons',
  initialState,
  reducers: {
    setButtons: (state, action: PayloadAction<AsyncState<IButtonsPageDto>>) => {
      state.content = action.payload
    }
  }
})

export const { setButtons } = buttonSlice.actions

export const selectButtons = (state: RootState) => state.buttons.content

export default buttonSlice.reducer
