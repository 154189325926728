import './MeasureDetails.scss'
import { MeasureDetailsTable } from './MeasureDetailsTable/MeasureDetailsTable'
import { MeasureDetailProps } from './measureDetails.props'
import { getMeasureChanges, getMeasureEntries, getMeasureFocusAreas, getMeasurePreconditions, getMeasureSummary } from './MeasureDetails.actions'
import { useAppSelector } from 'stores/hooks'
import { LoadingState } from 'services/constants'
import { useMeasureChangesData, useMeasureEntryData, useMeasureFocusAreaData, useMeasurePreconditionsData, useMeasureSummaryData } from './measureDetails.hooks'
import { LoadingSpinner } from 'components'
import { MeasureChangeHistory } from './MeasureChangeHistory/MeasureChangeHistory'
import { AsyncState } from 'services/types'
import React from 'react'

const MeasureDetailsContainer = (props:{children?: React.ReactNode}) =>
    <div className='measure-details'>
        {props.children}
    </div>

export const MeasureDetails = (props: MeasureDetailProps) => {
  useMeasureEntryData(props.measureId)
  useMeasureFocusAreaData(props.measureId)
  useMeasureChangesData(props.measureId)
  useMeasureSummaryData(props.measureId)
  useMeasurePreconditionsData(props.measureId)

  const measureEntries = useAppSelector(getMeasureEntries(props.measureId))
  const measureFocusAreas = useAppSelector(getMeasureFocusAreas(props.measureId))
  const measureChanges = useAppSelector(getMeasureChanges(props.measureId))
  const measureSummary = useAppSelector(getMeasureSummary(props.measureId))
  const measurePreconditions = useAppSelector(getMeasurePreconditions(props.measureId))

  const checkAsyncStates = (states: AsyncState<any>[]) => {
    for (let i = 0; i < states.length; i++) {
      if (states[i]?.kind !== LoadingState.Success) return false
    }
    return true
  }

  if (checkAsyncStates([measureEntries, measureFocusAreas, measureChanges, measureSummary, measurePreconditions])) {
    return (
        <MeasureDetailsContainer>
            <MeasureDetailsTable measureEntries={(measureEntries as any).response} measureFocusAreas={(measureFocusAreas as any).response}
            measureSummary={(measureSummary as any).response} measurePreconditions={(measurePreconditions as any).response}/>
            <MeasureChangeHistory data={(measureChanges as any).response}/>
        </MeasureDetailsContainer>
    )
  } else {
    return (
        <MeasureDetailsContainer>
            <LoadingSpinner data-testid='loading-spinner' />
        </MeasureDetailsContainer>
    )
  }
}
