import './Home.scss'
import { AsyncResponseHandler } from 'components/AsyncResponseHandler/AsyncResponseHandler'
import { FilterPane, PageLayout } from '../../components'
import { TileGrid } from '../../components/Tiles'
import { useAppSelector } from '../../stores/hooks'
import { Base } from '../base/Base'
import { getCategories, getHomePageTitle } from './Home.actions'
import { useCategoryData, useHomepageData } from './Home.hooks'
import { InfoModalService } from 'services/modals/InfoModal/InfoModalService'

export const Home = () => {
  const homePage = useAppSelector(getHomePageTitle)
  const categories = useAppSelector(getCategories)

  useHomepageData()
  useCategoryData()

  return (
    <Base sideBar={<FilterPane />}>
        <InfoModalService>
            <div className='Home' data-testid='Home'>
                <AsyncResponseHandler
                input={homePage}
                onSuccess={(homePageResponse) => {
                  return (
                    <PageLayout
                        pageTitle={homePageResponse.title}
                        showReportButton={true}
                        showFeedbackButton={true}
                        >
                        <AsyncResponseHandler
                            input={categories}
                            onSuccess={(categoryResponse) => <TileGrid data={categoryResponse} />}
                        />
                    </PageLayout>
                  )
                }}
                />
            </div>
      </InfoModalService>
  </Base>
  )
}
