import { Spinner } from 'react-bootstrap'
import './LoadingSpinner.scss'

export const LoadingSpinner = () => (
    <div className='d-flex justify-content-center mt-3 spinner' data-testid='loading-spinner'>
        <Spinner animation='border' role='status'>
            <span className='visually-hidden'>Loading...</span>
        </Spinner>
    </div>
)
