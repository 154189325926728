import { AsyncResponseHandler } from 'components/AsyncResponseHandler/AsyncResponseHandler'
import { ExportButton } from 'components/ExportButton/ExportButton'
import { useAppSelector } from 'stores/hooks'
import { Button } from '..'
import { selectButtons } from './pageLayout.actions'
import { usePageLayoutData } from './pageLayout.hooks'
import text from 'Texts'
import './PageLayout.scss'

type PageLayoutProps = {
  pageTitle: string;
  showReportButton: boolean;
  showFeedbackButton: boolean;
  bigTitle?: boolean;
  children?: JSX.Element;
};

export const PageLayout = (props: PageLayoutProps) => {
  const buttonsPage = useAppSelector(selectButtons)

  const testId = 'pagelayoutheadline'
  const headerElem = props.bigTitle ? <h1 data-testid={testId}>{props.pageTitle}</h1> : <h2 data-testid={testId}>{props.pageTitle}</h2>

  usePageLayoutData()
  return (
    <div className='PageLayout' data-testid='PageLayout'>
      <div className='d-flex justify-content-between align-items-center mb-3'>
        {headerElem}
        <div className='d-flex justify-content-between'>
          {props.showReportButton && (
            <ExportButton buttonText={text.buttonTitles.export}/>
          )}
          {props.showFeedbackButton &&
            <AsyncResponseHandler
              input={buttonsPage}
              onSuccess={(data) => {
                return (
                  <a href={data.feedbackButton?.targetAddress} rel='noreferrer'target='_blank'>
                    <Button
                      type='button'
                      className='ms-2 ps-5 pe-5'
                      buttonText={
                        data.feedbackButton?.name ??
                        text.buttonTitles.feedback
                      }
                    />
                  </a>
                )
              }}/>
          }
        </div>
      </div>
      <div className='children'>
        {props.children}
      </div>
    </div>
  )
}
