import React from 'react'
import { Container, Nav, Navbar, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import text from '../../Texts'
import './NavBar.scss'

export const NavBar = () => (
  <Navbar variant='dark' expand='md' className='p-0'>
    <Container fluid>
      <Row className='m-auto' style={{ width: '1400px' }}>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='rvo-navbar'>
            <NavLink className='rvo-nav-link' exact to='/'>
              {text.navigationBarTitles.home}
            </NavLink>
            <NavLink className='rvo-nav-link' exact to='/uitleg'>
              {text.navigationBarTitles.uitleg}
            </NavLink>
            <NavLink className='rvo-nav-link' exact to='/overons'>
              {text.navigationBarTitles.overons}
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Row>
    </Container>
  </Navbar>
)
