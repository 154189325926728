import './MeasureType.scss'
import { IMeasureDto, IMeasureTypeDto } from
  '../../../models/generated_models'
import { useAppSelector } from '../../../stores/hooks'
import { getMeasures } from '../MeasureTypeOverview/MeasureTypeOverview.actions'
import { Measure } from '../Measure/Measure'
import { useMeasureData, useNoResultsText } from './MeasureType.hooks'
import { AsyncResponseHandler } from 'components/AsyncResponseHandler/AsyncResponseHandler'
import { LoadingSpinner } from 'components'

export type MeasureTypeProps = {
    data: IMeasureTypeDto;
};

/**
 * Container for this component
 *
 * @param {({children?: JSX.Element | JSX.Element[] | string;})} props
 * @return {*}
 */
const MeasureTypeContainer = (props: { children?: JSX.Element | JSX.Element[] | string; }) => {
  return (
        <div className='MeasureType' data-testid='MeasureType'>
            {props.children}
        </div>
  )
}

/**
   * Display info text
   *
   * @param {{text: string}} props info text
   * @return {*}
   */
const DisplayInfo = (props: { text: string }) => {
  return <MeasureTypeContainer>
               <p>{props.text}</p>
           </MeasureTypeContainer>
}

/**
   * Display the measures in an accordion
   *
   * @param {{measures: IMeasureDto[]}} props
   * @return {*}
   */
const DisplayMeasures = (props: { measures: IMeasureDto[] }) => {
  return <MeasureTypeContainer>
               { props.measures.map((measure: IMeasureDto, index: number) => {
                 return (<Measure data={measure} key={measure.id}/>)
               }) }
           </MeasureTypeContainer>
}

export const MeasureType = (props: MeasureTypeProps): JSX.Element => {
  useMeasureData(props.data.id)

  const measures = useAppSelector(getMeasures(props.data.id))

  return (
    <AsyncResponseHandler
        input={measures}
        onLoading={
            <MeasureTypeContainer>
                <LoadingSpinner/>
            </MeasureTypeContainer>
        }
        onSuccess={(data) => {
          if (data.length === 0) {
            return <DisplayInfo
                text={useNoResultsText()}/>
          } else {
            return <DisplayMeasures measures={data}/>
          }
        }}
    />
  )
}
