import './MeasureTypeOverview.scss'
import { IMeasureTypeDto } from '../../../models/generated_models'
import { getMeasureTypes } from '../../../pages/home/Home.actions'
import { useAppSelector } from '../../../stores/hooks'
import { MeasureType } from '../MeasureType/MeasureType'
import { UseMeasureTypes } from './MeasureTypeOverview.hooks'
import { AsyncResponseHandler } from 'components/AsyncResponseHandler/AsyncResponseHandler'
import { MeasureTypeAccordion } from '../MeasureType/MeasureTypeAccordion/MeasureTypeAccordion'

type MeasureTypesProps = {
  categoryId: number;
};

const MeasureTypeCollection = (props:{measureTypes: IMeasureTypeDto[]}) => {
  return (
        <>
        {
            props.measureTypes.map((measureType: IMeasureTypeDto, index: number) => {
              return (
                <MeasureTypeAccordion data={measureType} key={index}>
                    <MeasureType data={measureType} key={index}></MeasureType>
                </MeasureTypeAccordion>
              )
            }
            )
        }
    </>
  )
}

/**
 * Renders all measure types for the given category id
 *
 * @param {MeasureTypesProps} props the component properties
 * @return {*}
 */
export const MeasureTypeOverview = (props: MeasureTypesProps) => {
  UseMeasureTypes(props.categoryId)
  const measureTypes = useAppSelector(getMeasureTypes)
  return (
    <div className='MeasureTypeOverview' data-testid='MeasureTypeOverview'>
        <AsyncResponseHandler
            input={measureTypes}
            onSuccess={(data) => <MeasureTypeCollection measureTypes={data}/>}
        />
    </div>
  )
}
