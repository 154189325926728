import { IFilterOptionItemDto } from 'models/generated_models'
import { ChangeEvent } from 'react'
import { Form } from 'react-bootstrap'

type FilterOptionProps = {
    label: string;
    defaultValue: number;
    onChange: (event: ChangeEvent<HTMLSelectElement>) => void
    options: IFilterOptionItemDto<number>[]
}

export const FilterOption = (props: FilterOptionProps) => {
  return (
    <Form.Group className='mb-3' controlId={`formGroup${props.label}`}>
        <Form.Label>{props.label}</Form.Label>
        <Form.Select
        value={props.defaultValue}
        onChange={props.onChange}
        aria-label='Default select example'
        >
        {props.options.map(
          (option, index) => (
            <option key={`fo-${index}`} value={option.code}>
                {option.name}
            </option>
          )
        )}
        </Form.Select>
    </Form.Group>
  )
}
