import React from 'react'
import { Accordion as AccordionBootstrap } from 'react-bootstrap'
import { AccordionTheme } from './Accordion.enum'
import './Accordion.scss'

export type AccordionProps = {
  header: JSX.Element | string;
  elementId: number;
  theme: number;
  isFontSizeSmaller?: boolean;
  openByDefault?: boolean | null;
  children?: JSX.Element | JSX.Element[] | string;
  className?: string;
  onHeaderClick?: () => void
};

const getClassName = (base:string, props:AccordionProps) => {
  if (props.className) {
    return `${base} ${props.className}`
  } else return base
}

export const Accordion = (props: AccordionProps) => {
  return (
    <AccordionBootstrap
      className={getClassName('Accordion', props)}
      defaultActiveKey='0'
      data-testid={`Accordion${props.elementId}`}
      key={`Accordion${props.elementId}`}
    >
      <AccordionBootstrap.Item
        eventKey={props.openByDefault ? '0' : '1'}
        className={`${AccordionTheme[props.theme].toString()} ${
          props.isFontSizeSmaller ? 'font-small' : ''
        }`}
      >
        <AccordionBootstrap.Header onClick={props.onHeaderClick}>{props.header}</AccordionBootstrap.Header>
        <AccordionBootstrap.Body className='p-0'>
          {props.children}
        </AccordionBootstrap.Body>
      </AccordionBootstrap.Item>
    </AccordionBootstrap>
  )
}
