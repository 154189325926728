import { IMeasureEntryDto } from 'models/generated_models'
import '../MeasureDetailsTable.scss'

type MeasureEntryProps = {
    data: IMeasureEntryDto,
    index: number
}

export const MeasureEntry = (props: MeasureEntryProps) => {
  return (
    <tr>
        <td>{props.data.omschrijving}</td>
        <td>{props.data.specificatie}</td>
        <td>{props.data.hoeveelheid}</td>
        <td>{props.data.eenheid}</td>
        <td>{props.data.brutoUurloon}</td>
        <td>{props.data.brutoMateriaal}</td>
        <td>{props.data.brutoMaterieel}</td>
        <td>{props.data.eenheidsprijs}</td>
        <td>{props.data.totaal}</td>
        <td>{props.data.totaalBk}</td>
    </tr>
  )
}
