import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IMeasureDto } from 'models/generated_models'
import { AsyncState } from 'services/types'
import { RootState } from 'stores'

export type MeasureTypesState = {
    measures: { [key: number]: AsyncState<IMeasureDto[]> }
}

export const initialState: MeasureTypesState = {
  measures: {}
}

export const measureTypesSlice = createSlice({
  name: 'measureTypes',
  initialState,
  reducers: {
    setMeasureTypes: (state, action: PayloadAction<{ measureTypeId: number, entries: AsyncState<IMeasureDto[]> }>) => {
      state.measures[action.payload.measureTypeId] = action.payload.entries
    }
  }
})

export const { setMeasureTypes } = measureTypesSlice.actions

export const getMeasures = (measureTypeId: number) => (state: RootState) => state.measureTypes.measures[measureTypeId]

export const MeasureTypesReducer = measureTypesSlice.reducer
