import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../stores'

export type WarningModalServiceState = {
    openModal: boolean,
  };

export const initialState: WarningModalServiceState = {
  openModal: false
}

export const warningModalServiceSlice = createSlice({
  name: 'warningModal',
  initialState,
  reducers: {
    setOpenWarningModal: (state, action: PayloadAction<boolean>) => {
      state.openModal = action.payload
    }
  }
})

export const {
  setOpenWarningModal
} = warningModalServiceSlice.actions

export const selectOpenWarningModal = (state: RootState) =>
  state.warningModalService.openModal

export default warningModalServiceSlice.reducer
