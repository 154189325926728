import './ExportButton.scss'
import text from '../../Texts'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { useService } from 'react-service-locator'
import { useAppSelector } from 'stores/hooks'
import { ExportService } from 'services/export/export.service'
import { selectAllExpandedMeasures } from 'components/Measures'
import { useAllowDownload, useExportRequestDto } from './ExportButton.hooks'
import { ExportButtonProps } from './ExportButton.props'

export const ExportButton = (props: ExportButtonProps) => {
  const exportService = useService(ExportService)

  const expandedMeasures = useAppSelector(selectAllExpandedMeasures)
  const allowDownload = useAllowDownload()
  const dto = useExportRequestDto()

  const handleExportClick = async (exportType: string) => {
    if (expandedMeasures.length > 0 && allowDownload) {
      exportService.getExportFileAsync(exportType, dto)
    }
  }

  const exportTypes = ['excel', 'pdf']

  return (
    <DropdownButton title={props.buttonText}>
        {
            exportTypes.map(
              (type) => {
                return (
                    <Dropdown.Item key={type} onClick={() => handleExportClick(type)} className='d-flex align-content-center'>
                        <span className='align-self-center'>{text.exportTypes[type]}</span>
                        <span className={`icon ${type} ml-auto`} role='img' aria-label={`Icoon ${type}`}></span>
                    </Dropdown.Item>
                )
              }
            )
        }
    </DropdownButton>
  )
}
