import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Home, OverOns, Uitleg } from '../../pages'
import './Routing.scss'

export function Routing () {
  return (
    <div data-testid='Routing'>
      <Switch>
        <Route exact path='/'>
          <Home />
        </Route>
        <Route path='/uitleg'>
          <Uitleg />
        </Route>
        <Route path='/overons'>
          <OverOns />
        </Route>
      </Switch>
    </div>
  )
}
