import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUitlegPageDto } from '../../models/generated_models'
import { AsyncState } from '../../services/types'
import { RootState } from '../../stores'

export type UitlegState = {
  content: AsyncState<IUitlegPageDto>
}

export const initialState: UitlegState = {
  content: { kind: 'unloaded' }
}

export const uitlegSlice = createSlice({
  name: 'uitleg',
  initialState,
  reducers: {
    setUitlegPage: (state, action: PayloadAction<AsyncState<IUitlegPageDto>>) => {
      state.content = action.payload
    }
  }
})

export const { setUitlegPage } = uitlegSlice.actions

export const getUitlegPageContent = (state: RootState) => state.uitlegPage.content

export default uitlegSlice.reducer
