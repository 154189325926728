import './MeasureDetailsTable.scss'
import text from '../../../../Texts'
import { IMeasureEntryDto, IMeasureFocusAreaDto, IMeasurePreconditionDto } from 'models/generated_models'
import { Table } from 'react-bootstrap'
import { MeasureEntry } from './Rows/MeasureEntry'
import { MeasureFocusArea } from './Rows/MeasureFocusArea'
import { MeasurePrecondition } from './Rows/MeasurePrecondition'
import { MeasureSummary } from './Rows/MeasureSummary'

export const MeasureDetailsTable = (props: {
  measureEntries: IMeasureEntryDto[],
  measureFocusAreas: IMeasureFocusAreaDto[],
  measureSummary: IMeasureEntryDto | undefined,
  measurePreconditions: IMeasurePreconditionDto[]
}) => {
  const measurePreconditions = props.measurePreconditions.map((entry, index) =>
        <MeasurePrecondition key={`measure-precondition-${index}`} data={entry} index={index} />
  )
  const measureFocusAreas = props.measureFocusAreas.map((entry, index) =>
        <MeasureFocusArea key={`measure-focus-areas-${index}`} data={entry} index={index} />
  )
  const measureEntries = props.measureEntries.map((entry, index) =>
  <MeasureEntry key={`measure-entries-${index}`} data={entry} index={index} />
  )
  return (
    <Table
      className='measure-details-table'
      responsive
      size='sm'
      data-testid='measure-detail-table'
    >
      <thead>
        <tr>
          <th>{text.measureDetails.columns.omschrijving}</th>
          <th>{text.measureDetails.columns.specificatie}</th>
          <th>{text.measureDetails.columns.hoeveelheid}</th>
          <th>{text.measureDetails.columns.eenheid}</th>
          <th>{text.measureDetails.columns.totaalUurloon}</th>
          <th>{text.measureDetails.columns.totaalMateriaal}</th>
          <th>{text.measureDetails.columns.totaalMaterieel}</th>
          <th>{text.measureDetails.columns.eenheidsprijs}</th>
          <th>{text.measureDetails.columns.totaal}</th>
          <th>{text.measureDetails.columns.totaalBk}</th>
        </tr>
      </thead>
      <tbody>
        {props.measureEntries.length === 0 && props.measureFocusAreas.length === 0 && props.measurePreconditions.length === 0
          ? (
          <tr data-testid='no-entries-message'>
            <td colSpan={12} className='text-center'>{text.measureDetails.noDetailsForMeasure}</td>
          </tr>
            )
          : <>
        {props.measureSummary &&
         <MeasureSummary data={props.measureSummary} />
        }
        {props.measurePreconditions.length > 0 &&
         measurePreconditions
        }
        {props.measureEntries.length > 0 &&
         measureEntries
        }
        {props.measureFocusAreas.length > 0 &&
         measureFocusAreas
        }
            </>
        }
      </tbody>
    </Table>
  )
}
