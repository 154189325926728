import { Service } from 'react-service-locator'
import { BaseService } from 'services/base.services'
import { makeQueryString } from '../../helpers/makeQueryStringHelper'
import { IFilterCombinationDto, IMeasureDto } from '../../models/generated_models'

@Service()
export class MeasureTypeService extends BaseService {
  async getMeasuresAsync (measureTypeId: number, selectedFilterOptions: IFilterCombinationDto): Promise<IMeasureDto[]> {
    return await fetch(`${this.basePath}/measuretypes/${measureTypeId}/measures?${makeQueryString(selectedFilterOptions)}`)
      .then(x => {
        if (x.ok) {
          return x.json()
        } else {
          throw Error('Backend did not respond with success status')
        }
      })
  }
}
